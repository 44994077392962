import React, { createContext, useContext, useState } from 'react'

const TabsLinkContext = createContext()

export const useTabsLinkContext = () => useContext(TabsLinkContext)

export const TabsLinkProvider = ({ children }) => {
  const [tabsLink, setTabsLink] = useState(false)

  const updateTabsLink = (status) => {
    setTabsLink(status)
  }

  return (
    <TabsLinkContext.Provider value={{ tabsLink, updateTabsLink }}>
      {children}
    </TabsLinkContext.Provider>
  )
}
