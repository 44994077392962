import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import ButtonTabs from '../../components/ButtonTabs'
import Access from './components/Access'
import Files from './components/Files'
import Validator from './components/Validator'
import BomGen from './components/BomGen'
import TabsLinkGen from './components/TabsLinkGen'
import SynGen from './components/SynGen'
import { getProject } from '../../../controllers/project'

function Project() {
  const { idOperator, idProject } = useParams()
  const navigate = useNavigate()

  const [project, setProject] = useState(undefined)

  const [option, setOption] = useState(
    localStorage.getItem('projectOption') || undefined
  )

  useEffect((_) => {
    loadProject()
  }, [])

  const loadProject = async () => {
    const result = await getProject({
      idOperator,
      idProject,
    })

    if (result) {
      setProject(result)
    }
  }

  const handleFilterClick = (filter) => {
    if (filter === 'operator') {
      navigate(`/operator/${idOperator}`)
    } else {
      setOption(filter)
      localStorage.setItem('projectOption', filter)
    }
  }

  if (project) {
    return (
      <>
        <div className="contentTitle">
          <h2>Projeto: {project.name}</h2>
          <small>
            Criado a: {new Date(project.createdAt).toLocaleString('pt')}
          </small>
        </div>
        <div className="content">
          {/* if(checkPrivilege(['ADMINS', 'REGULARS'])){ */}
          <ButtonTabs
            filters={[
              'files',
              'validator',
              'bomGen',
              'tabLig',
              'synGen',
              'access',
              'operator',
            ]}
            handleFilterClick={handleFilterClick}
            selectedFilter={option}
          />
          <hr></hr>
          <div className="container">
            {option === 'files' ? (
              <Files />
            ) : option === 'validator' ? (
              <Validator />
            ) : option === 'access' ? (
              <Access />
            ) : option === 'bomGen' ? (
              <BomGen />
            ) : option === 'tabLig' ? (
              <TabsLinkGen />
            ) : option === 'synGen' ? (
              <SynGen />
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    )
  }
}

export default Project
