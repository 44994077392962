// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bts {
  border-radius: 25px;
  padding: 1rem;
  margin: 0.5rem 0;
  background-color: gainsboro;
}

.bts-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.bts-form div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/Projects/components/TabsLinkGen.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,gBAAgB;EAChB,2BAA2B;AAC7B;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;AACb","sourcesContent":[".bts {\n  border-radius: 25px;\n  padding: 1rem;\n  margin: 0.5rem 0;\n  background-color: gainsboro;\n}\n\n.bts-form {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.bts-form div {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
