import axios from 'axios'
import Cookies from 'js-cookie'

import { statusCheck } from './middlewares/responseChecker'
import { env } from '../config/config'

const API = env('SERVER_API')

export const checkPreTabsLink = async (args) => {
  try {
    const { idOperator, idProject } = args

    const res = await axios.post(
      `${API}/generatorTabsLink/preTabsLink/check`,
      {
        idOperator,
        idProject,
      },
      JSON.parse(Cookies.get('authHeader'))
    )

    if (statusCheck(res)) {
      return res.data
    }
  } catch (error) {
    console.log('ERRO: executePreTabsLink')
  }
}

export const getTabsLinkResults = async (args) => {
  try {
    const { idOperator, idProject } = args

    const res = await axios.get(
      `${API}/generatorTabsLink/tabsLinkResults?idOperator=${idOperator}&idProject=${idProject}`,
      JSON.parse(Cookies.get('authHeader'))
    )

    if (statusCheck(res)) {
      return res.data
    }
  } catch (error) {
    console.log('ERRO: getTabsLinkResults')
  }
}

export const executeTabsLink = async (args) => {
  try {
    const { idOperator, idProject, bts } = args

    const res = await axios.post(
      `${API}/generatorTabsLink/executeTabsLink`,
      {
        idOperator,
        idProject,
        bts,
      },
      JSON.parse(Cookies.get('authHeader'))
    )

    if (statusCheck(res)) {
      return res.data
    } else {
      return false
    }
  } catch (error) {
    console.log('ERRO: executeTabsLink')
  }
}

export const downloadTabsLink = async (args, downloadProgress) => {
  try {
    const { idOperator, idProject, file } = args

    const controller = new AbortController()
    const signal = controller.signal

    const res = await axios({
      url: `${API}/generatorTabsLink/tabsLinkResults/download`,
      method: 'GET',
      params: {
        idOperator,
        idProject,
        file,
      },
      responseType: 'blob',
      ...JSON.parse(Cookies.get('authHeader')),
      onDownloadProgress: downloadProgress,
      signal,
    })

    if (statusCheck(res)) {
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', file)
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(url)

      return true
    } else {
      controller.abort()
      return false
    }
  } catch (error) {
    console.log('ERRO: getProjects')
  }
}
