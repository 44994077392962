import React, { useState } from 'react'
import { useFileContext } from '../../../context/FileContext'
import { useAuthContext } from '../../../context/AuthContext'
import { env } from '../../../../config/config'

const Category = ({
  category,
  categoryFiles,
  deleteFile,
  showDeleted,
  downloadFile,
}) => {
  const { downloadProgress } = useFileContext()
  const { privileges } = useAuthContext()

  return (
    <>
      <ul className="card-list">
        {categoryFiles.map(
          (item, idx) =>
            (!privileges.includes(env('PARTNER')) ||
              (privileges.includes('PARTNER') && !item.inactive)) &&
            ((showDeleted && item.inactive) ||
              (!showDeleted && !item.inactive)) && (
              <li key={idx} className="card">
                <div className="card-content-ver">
                  <ul>
                    <li>
                      <p>
                        Arquivo: <strong>{item.name}</strong>
                      </p>
                    </li>
                    <li>
                      {!privileges.includes(env('PARTNER')) && (
                        <p>
                          Criado por: <strong>{item.creator}</strong>
                        </p>
                      )}
                    </li>
                    <li>
                      <p>
                        Criado a:{' '}
                        {new Date(item.createdAt).toLocaleString('pt')}
                      </p>
                    </li>
                    {item.inactive && (
                      <>
                        <li>
                          <p>
                            Apagado por: <strong>{item.destroyer}</strong>
                          </p>
                        </li>
                        <li>
                          <p>
                            Apagado em:{' '}
                            {new Date(item.destroyedAt).toLocaleString('pt')}
                          </p>
                        </li>
                      </>
                    )}
                  </ul>

                  <ul className="button-list-hor">
                    <li className="button-item">
                      <button
                        className="button"
                        onClick={() => downloadFile(category, item.name)}
                        disabled={downloadProgress ? true : false}
                      >
                        {downloadProgress
                          ? `${downloadProgress}%`
                          : 'Transferir'}
                      </button>
                    </li>
                    {(!item.inactive || privileges.includes(env('ADMIN'))) && (
                      <li className="button-item">
                        <button
                          className="button delete"
                          onClick={(_) => deleteFile(category, item.name)}
                          disabled={downloadProgress ? true : false}
                        >
                          Apagar
                        </button>
                      </li>
                    )}
                  </ul>
                </div>
              </li>
            )
        )}
      </ul>
    </>
  )
}

export default Category
