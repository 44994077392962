import React, { useState, useEffect } from 'react'

import {
  downloadTabsLink,
  executeTabsLink,
  getTabsLinkResults,
} from '../../../../controllers/tabsLinkGen'
import { useParams } from 'react-router-dom'
import { useTabsLinkContext } from '../../../context/TabsLinkContext'

import './TabsLinkGen.css'

function TabsLinkGen() {
  const { idOperator, idProject } = useParams()
  const { tabsLink, updateTabsLink } = useTabsLinkContext()
  const [results, setResults] = useState([])
  const [bts, setBts] = useState({})
  const [progress, setProgress] = useState(null)

  useEffect(() => {
    if (!tabsLink) {
      loadResults()
    }
  }, [tabsLink])

  const loadResults = async () => {
    const tabsLinkResults = await getTabsLinkResults({
      idOperator,
      idProject,
    })

    if (tabsLinkResults) {
      if (tabsLinkResults.files) {
        setResults(tabsLinkResults.files)
      }
      if (tabsLinkResults.bts) {
        setBts(tabsLinkResults.bts)
      }
    }
  }

  const handleExecuteTabsLink = async (event) => {
    updateTabsLink(true)

    setResults([])

    const results = await executeTabsLink({
      idOperator,
      idProject,
      bts,
    })

    if (results) {
      if (!results.result) {
        setBts(results.bts)
      }
    }

    updateTabsLink(false)
  }

  const downloadFile = async (file) => {
    await downloadTabsLink(
      {
        idOperator,
        idProject,
        file,
      },
      (loaded) => {
        const progress = Math.round((loaded.loaded / loaded.total) * 100)
        setProgress(progress)
      }
    )

    setProgress(undefined)
  }

  return (
    <>
      <div className="grid-two">
        <div className="grid-column">
          <div className="column-title">
            <h2>Alocação Juntas</h2>
          </div>
          <hr></hr>
          <ul className="button-list-ver">
            <li className="button-item">
              <button
                className="button"
                onClick={handleExecuteTabsLink}
                disabled={tabsLink}
              >
                Gerar Alocação Juntas
              </button>
            </li>
          </ul>

          {Object.keys(bts).length > 0 &&
            Object.entries(bts).map(([key, value]) => (
              <div className="bts" key={key}>
                <h4>Antena - {key}</h4>
                <ul className="bts-form-list">
                  <li className="bts-form" key={key}>
                    <div>
                      <label>Bloco: </label>
                      <input
                        className="inputBox"
                        type="text"
                        value={value.block}
                        onChange={(e) =>
                          setBts((prevState) => ({
                            ...prevState,
                            [key]: {
                              ...prevState[key],
                              block: e.target.value,
                            },
                          }))
                        }
                      ></input>
                    </div>
                    <div>
                      <label>Fibras: </label>
                      <input
                        className="inputBox"
                        type="text"
                        value={value.fibers}
                        onChange={(e) => {
                          const inputValue = e.target.value

                          const sanitizedValue = inputValue
                            .replace(/^0+/, '')
                            .replace(/[^\d]/g, '')

                          setBts((prevState) => ({
                            ...prevState,
                            [key]: {
                              ...prevState[key],
                              fibers: parseInt(
                                sanitizedValue == '' ? 0 : sanitizedValue
                              ),
                            },
                          }))
                        }}
                      />
                    </div>
                  </li>
                </ul>
              </div>
            ))}
        </div>

        <div className="grid-column">
          <h3>Resultados</h3>
          <hr></hr>
          <ul className="card-list">
            {results.length > 0 ? (
              results.map((s) => (
                <li className="card" key={s}>
                  <div className="card-content-hor">
                    <ul>
                      <li>
                        <p>
                          Arquivo: <strong>{s}</strong>
                        </p>
                      </li>
                    </ul>

                    <ul className="button-list-hor">
                      <li className="button-item">
                        <button
                          className="button"
                          onClick={() => downloadFile(s)}
                          disabled={progress ? true : false}
                        >
                          {progress ? `${progress}%` : 'Transferir'}
                        </button>
                      </li>
                    </ul>
                  </div>
                </li>
              ))
            ) : (
              <h3>Sem Resultados...</h3>
            )}
          </ul>
        </div>
      </div>
    </>
  )
}

export default TabsLinkGen
